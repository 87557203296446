@import url(https://fonts.googleapis.com/css2?family=Russo+One&display=swap);
.nav {
    position: fixed;
    display: flex;
    align-items: flex-start;
    top: 3rem;
    grid-gap: 1.5625rem;
    gap: 1.5625rem;
    left: 50%;
  }
  
  .nav-about {
    transform: translateX(-1.56rem);
  }
  .nav-skills {
    transform: translateX(-6.25rem);
  }
  .nav-projects {
    transform: translateX(-10.94rem);
  }
  .nav-contact {
    transform: translateX(-15.62rem);
  }
  
  a {
    width: 3.125rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 0.625rem;
    gap: 0.625rem;
  }
  
  .page-title {
    font-size: 0.875rem;
    color: white;
    cursor: default;
  }
  
  .nav-link {
    opacity: 0.7;
  }
  
  .nav-link:hover {
    opacity: 1;
  }
  
  .nav-link.current {
    opacity: 1;
  }
  
  @media (max-width: 1100px) {
    .nav-about,
    .nav-skills,
    .nav-projects,
    .nav-contact {
      transform: translateX(0);
    }
    .nav {
      top: calc(100vh - 8rem);
      transform: translateX(-50%);
    }
    .nav-link {
      width: 2rem;
    }
    .nav-link img {
      width: 2rem;
    }
    .page-title {
      display: none;
    }
  }
  
  @media (max-width: 700px) {
    .nav-about,
    .nav-skills,
    .nav-projects,
    .nav-contact {
      transform: translateX(0);
    }
    .nav {
      top: calc(100vh - 3rem);
      transform: translateX(-50%);
    }
    .nav-link {
      width: 2rem;
    }
    .nav-link img {
      width: 2rem;
    }
    .page-title {
      display: none;
    }
  }
  
.avatar {
    position: fixed;
    overflow: hidden;
    z-index: -2;
  }
  
  .avatar.about {
    height: 89vh;
    bottom: -8vh;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .avatar.skills {
    height: 190vh;
    bottom: -110vh;
    left: -1.5rem;
    transform: translateX(0%) scaleX(-1);
  }
  
  .shadow-overlay-skills {
    height: 100vh;
    width: 100vh;
    display: block;
    position: fixed;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.25),
      rgba(255, 255, 255, 0)
    );
    z-index: -1;
  }
  
  @media (max-width: 1100px) {
    .avatar.skills {
      z-index: -5;
      height: 95vh;
      bottom: -15vh;
    }
  }
  
  @media (max-width: 700px) {
    .avatar.about {
      z-index: -5;
      height: 95vh;
      bottom: -15vh;
    }
  }
.about-menu {
    width: 19rem;
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 60%;
    transform: translateY(-60%);
    left: 10vw;
  }
  
  .item {
    height: 3.7rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    font-weight: lighter;
  }
  
  .title {
    font-size: 1rem;
    font-weight: lighter;
    padding-right: 2.5rem;
  }
  
  .item.active .title {
    font-size: 1.5rem;
    font-weight: lighter;
  }
  
  .sub-container {
    width: 18.5rem;
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 32%;
    right: 10vw;
    cursor: pointer;
    font-weight: lighter;
  }
  
  .active-subheading {
    cursor: default;
  }
  
  .item.active:nth-child(1) {
    background: linear-gradient(90deg, var(--transparent), var(--purple) 70%);
    border-radius: var(--radius-right);
  }
  .item.active:nth-child(2) {
    background: linear-gradient(90deg, var(--transparent), var(--blue) 70%);
    border-radius: var(--radius-right);
  }
  .item.active:nth-child(3) {
    background: linear-gradient(90deg, var(--transparent), var(--cyan) 70%);
    border-radius: var(--radius-right);
  }
  
  .sub-container-1.active-subheading h3,
  .sub-container-2.active-subheading h3,
  .sub-container-3.active-subheading h3 {
    border-radius: var(--radius-left);
  }
  
  .sub-container-1.active-subheading h3 {
    background: linear-gradient(90deg, var(--purple), var(--transparent) 100%);
  }
  .sub-container-2.active-subheading h3 {
    background: linear-gradient(90deg, var(--blue), var(--transparent) 100%);
  }
  .sub-container-3.active-subheading h3 {
    background: linear-gradient(90deg, var(--cyan), var(--transparent) 100%);
  }
  
  .item.active {
    position: relative;
    cursor: default;
  }
  
  .p-container {
    display: none;
  }
  
  .active-subheading .p-container {
    display: block;
    font-size: 1rem;
    font-weight: lighter;
  }
  
  .icon-title-container {
    display: flex;
    grid-gap: 1.2rem;
    gap: 1.2rem;
    margin-bottom: 0.5rem;
    cursor: default;
  }
  
  .icon {
    width: auto;
    height: auto;
  }
  
  .sub-container-1 h3,
  .sub-container-2 h3,
  .sub-container-3 h3 {
    position: relative;
    margin: 0.2rem 0;
    padding: 0.5rem 0 0.5rem 1rem;
    font-weight: lighter;
  }
  
  @media (max-width: 700px) {
    .sub-container {
      right: 1rem;
    }
    .item.active .title {
      font-size: 1rem;
      font-weight: lighter;
    }
    .item {
      height: 2rem;
      font-weight: lighter;
    }
  }
  @media (max-width: 700px) {
    .about-menu{
      top: 40%;
      left: auto;
    }
    .sub-container {
      width: 15rem;
      top: 50%;
      left: 40%;
      transform: translateX(-50%);
    }
    .title {
      font-size: 1rem;
      font-weight: lighter;
      padding-right: 2.5rem;
    }
    .active-subheading .p-container {
      font-size: 0.8rem;
      font-weight: lighter;
    }
    .icon-title-container,
    .icon {
      display: none;
    }
    h1 {
      font-size: 0.8rem;
      font-weight: lighter;
    }
    h2,
    h3,
    p {
      font-size: 0.7rem;
      font-weight: lighter;
    }
  }
  
.skill-menu {
    width: 31.2rem;
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 30%;
    right: 15vw;
    align-items: flex-end;
  }
  
  .skill-item {
    height: 3.7rem;
    width: 50%;
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
  }
  
  .skill-title {
    font-size: 1rem;
    padding-right: 4.5rem;
  }
  
  .skill-item.activeSkill .skill-title {
    font-size: 1.5rem;
  }
  
  .activeSkill {
    border-radius: var(--radius-left);
    cursor: default;
  }
  
  .skill-item.activeSkill:nth-child(1) {
    background: linear-gradient(270deg, #ffffff00, var(--purple) 70%);
  }
  .skill-item.activeSkill:nth-child(2) {
    background: linear-gradient(270deg, #ffffff00, var(--blue) 70%);
  }
  
  .skill-icon {
    width: 5rem;
    height: 5rem;
    position: absolute;
    top: 1.8rem;
    left: 8rem;
  }
  
  .skill-sub-container-1 h3,
  .skill-sub-container-2 h3 {
    position: relative;
    margin: 0;
  }
  
  .menu {
    width: 19rem;
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 50%;
    transform: translateY(-25%);
    right: 15vw;
  }
  
  .skill-sub-container-1,
  .skill-sub-container-2 {
    display: flex;
    justify-content: right;
    grid-gap: 2rem;
    gap: 2rem;
  }
  
  .level-container {
    display: flex;
    grid-gap: 0.3rem;
    gap: 0.3rem;
  }
  
  .level-point {
    width: 3rem;
    height: 2rem;
    border-radius: 0.2rem;
  }
  
  .filled {
    background: linear-gradient(180deg, #236473, #25afce, #7ce7ff);
  }
  .unfilled {
    background: linear-gradient(180deg, #1c8298, #074654, #074654);
  }
  
  .skill-sub-container {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    grid-gap: 0.4rem;
    gap: 0.4rem;
  }
  
  @media (max-width: 1100px) {
    .skill-item.activeSkill .skill-title {
      font-size: 1rem;
    }
    .skill-icon {
      display: none;
    }
    .level-point {
      width: 1.2rem;
      height: 1rem;
      border-radius: 0.1rem;
    }
    .level-container {
      grid-gap: 0.15rem;
      gap: 0.15rem;
    }
    .skill-sub-container-1,
    .skill-sub-container-2 {
      grid-gap: 0.5rem;
      gap: 0.5rem;
    }
    .skill-sub-container {
      margin-top: 2rem;
      grid-gap: 0.2rem;
      gap: 0.2rem;
    }
    h1 {
      font-size: 0.8rem;
    }
    h2,
    h3,
    p {
      font-size: 0.7rem;
    }
    .skill-menu {
      flex-direction: column;
      left: 50%;
      transform: translateX(-50%);
      align-items: center;
      top: 30%;
    }
    .skill-item {
      height: 2rem;
      width: 12em;
    }
  }
  @media (max-width: 700px) {
    .skill-item.activeSkill .skill-title {
      font-size: 1rem;
    }
    .skill-icon {
      display: none;
    }
    .level-point {
      width: 1.3rem;
      height: 1rem;
      border-radius: 0.1rem;
    }
    .level-container {
      grid-gap: 0.11rem;
      gap: 0.11rem;
    }
    .skill-sub-container-1,
    .skill-sub-container-2 {
      grid-gap: .5rem;
      gap: .5rem;
    }
    .skill-sub-container {
      margin-top: 3rem;
      grid-gap: 0.2rem;
      gap: 0.2rem;
    }
    h1 {
      font-size: 0.2rem;
    }
    h2,
    h3,
    p {
      font-size: 0.7rem;
    }
    .skill-menu {
      flex-direction: column;
      left: 45%;
      transform: translateX(-50%);
      align-items: center;
      top: 35%;
    }
    .skill-item {
      height: 3rem;
      width: 15em;
    }
  }
.project-menu {
    width: 40rem;
    position: fixed;
    display: flex;
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .project-items-container {
    flex-direction: column;
    display: flex;
    position: relative;
    width: 40%;
    margin-top: 12rem;
    align-items: flex-end;
  }
  
  .project-item {
    height: 3.75rem;
    width: 90%;
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
  }
  
  .project-item.activeProject .title {
    font-size: 1.5rem;
    font-weight: lighter;
  }
  
  .activeProject {
    border-radius: var(--radius-left);
    cursor: default;
    font-weight: lighter;
  }
  
  .project-item.activeProject:nth-child(1) {
    background: linear-gradient(270deg, #ffffff00, var(--purple) 70%);
  }
  .project-item.activeProject:nth-child(2) {
    background: linear-gradient(270deg, #ffffff00, var(--blue) 70%);
  }
  .project-item.activeProject:nth-child(3) {
    background: linear-gradient(270deg, #ffffff00, var(--cyan) 70%);
  }
  
  .project-sub-container {
    width: 60%;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    grid-gap: 0.375rem;
    gap: 0.375rem;
    font-weight: lighter;
  }
  .project-sub-container h3 {
    position: relative;
    margin: 0;
    font-weight: lighter;
  }
  .project-sub-container img {
    width: 90%;
    object-fit: cover;
    border-radius: 0.5rem 3rem 0.5rem 0.5rem;
    opacity: 0.9;
  }
  .project-sub-container p {
    font-size: 0.875rem;
    font-weight: lighter;
  }
  
  .link-container a {
    color: white;
    text-decoration: none;
    margin-top: 1rem;
    width: 50%;
    height: 2.75rem;
    text-align: center;
  }
  
  .link-container {
    display: flex;
    justify-content: space-around;
  }
  
  @media (max-width: 1100px) {
    .project-menu {
      width: 28rem;
      top: 20%;
      transform: translateX(-50%);
    }
    .project-item {
      height: 2rem;
      width: 100%;
    }
    .project-item.activeProject .title {
      font-size: 1rem;
      font-weight: lighter;
    }
    .title {
      font-size: 0.8rem;
      font-weight: lighter;
    }
  }
  @media (max-width: 700px) {
    .project-menu {
      width: 30rem;
      top: 15%;
      flex-direction: column;
      align-items: center;
    }
    .project-menu p {
      font-size: 0.6rem;
      font-weight: lighter;
    }
    .project-sub-container {
      margin-top: 0.9rem;
      width: 12rem;
    }
    .project-sub-container img {
      width: 10rem;
    }
    .link-container a {
      margin-top: 0.5rem;
      width: 50%;
      height: 2rem;
    }
    .project-items-container {
      margin-top: 0;
    }
  }
  
.contact-menu {
    position: absolute;
    top: 57%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  form {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    width: 18.5rem;
  }
  
  .form-group {
    width: 100%;
  }
  
  input,
  #message {
    width: 100%;
    background-color: #00000040;
    border-radius: 0.3rem;
    border: 0.07rem solid rgba(255, 255, 255, 0.4);
    outline: none;
    color: white;
    padding: 0.5rem;
    font-weight: bold;
    font-size: 1rem;
    font-family: "Russo One", sans-serif;
  }
  
  input {
    height: 1.9rem;
  }
  
  ::placeholder {
    color: white;
    font-weight: 400;
  }
  
  #message {
    height: 6.2rem;
    resize: none;
  }
  
  button {
    color: white;
    font-size: 1rem;
    font-weight: 600;
    width: 102%;
    padding: 0.5rem 0;
    background-color: transparent;
    border: none;
    font-family: "Russo One", sans-serif;
    cursor: pointer;
  }
  
  .success-message {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #00000030;
    width: 100%;
    height: 12.5rem;
    border-radius: 0.3rem;
    border: 0.07rem solid rgba(255, 255, 255, 0.7);
  }
  
  .success-message p {
    margin: 0.5rem;
    padding: 0 3.7rem;
  }
  
  .error-message {
    padding: 0.3rem;
    color: white;
    background-color: rgb(255, 59, 59);
    border-radius: 0.2rem;
  }
  
  label {
    display: none;
  }
  
  @media (max-width: 1100px) {
    .contact-menu {
      top: 50%;
    }
  }
  @media (max-width: 700px) {
    .contact-menu {
      width: 70%;
      top: 50%;
    }
    form {
      width: 100%;
    }
    input,
    #message {
      width: 95%;
    }
    .success-message p {
      padding: 0 0.5rem;
    }
  }
body {
  font-family: "Russo One", sans-serif;
  color: white;
  -webkit-tap-highlight-color: transparent;
}

:root {
  --purple: #540351;
  --blue: #3627b1;
  --cyan: #25afce;
  --transparent: #00000000;
  --radius-left: 3rem 0 0 3rem;
  --radius-right: 0 3rem 3rem 0;
}

button:hover,
.class-sub-container a:hover,
.project-item:hover,
.skill-item:hover,
.item:not(.active):hover,
.sub-container-2:not(.active-subheading):hover,
.sub-container-3:not(.active-subheading):hover {
  background-image: linear-gradient(90deg, #ffffff00, #ffffff42 50%, #ffffff00);
}
#bg {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -5;
  }
  
  .shadow-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -4;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  @media (max-width: 700px) {
    .shadow-overlay {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
#playerStats {
    width: 15rem;
    height: 4rem;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 3rem;
    left: 3rem;
  }
  
  h1 {
    margin: 0;
    font-weight: lighter;
    font-size: 1.5rem;
  }
  
  h2 {
    margin: 0;
    font-weight: lighter;
    font-size: 1rem;
  }
  
  #playerStats__lines {
    display: flex;
    font-weight: lighter;
    align-items: center;
  }
  
  #playerStats__lines__thick {
    display: block;
    background-color: white;
    height: 0.09rem;
    width: 10.75rem;
  }
  
  #playerStats__lines__thin {
    display: block;
    background-color: lightgray;
    height: 0.1em;
    width: 7.2rem;
  }
  
  @media (max-width: 1100px) {
    #playerStats {
      left: 50%;
      transform: translateX(-50%);
      width: 8rem;
      height: 2.5rem;
      top: 2rem;
    }
    h1 {
      font-size: 0.8rem;
      font-weight: lighter;
    }
    h2,
    h3,
    p {
      font-size: 0.7rem;
      font-weight: lighter;
    }
  }
